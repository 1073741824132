body {
    background-color: $primary;
}

.main {
    position: relative;
    
    & > .row {
        border-top: 12px solid #000;
        border-radius: 10px 10px 0 0;
    }
}

.content,
.content-top,
.content-bottom {
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.content {
    padding: ($spacer * 1.5);
}