.content-sidebar {
    @include media-breakpoint-down(lg) {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        @include make-col-ready();
        @include make-col(4);
        
        & + .content-wrapper {
            @include make-col-ready();
            @include make-col(8);
        }
    }

    @include media-breakpoint-up(xl) {
        @include make-col(3);
        
        & + .content-wrapper {
            @include make-col(9);
        }
    }

    
    .moduletable {
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-0.5 * var(--bs-gutter-x));
        margin-left: calc(-0.5 * var(--bs-gutter-x));
        background-color: $gray-100;
        height: 100%;
    }

    .nav {
        --bs-nav-link-color: $gray-600;
        --bs-nav-link-padding-x: 1rem;
        --bs-nav-link-padding-y: 0.5rem;

        flex-direction: column;
        text-transform: uppercase;
        letter-spacing: 1px;

        > .nav-item {
            margin-bottom: ($spacer);

            // & + .nav-item {
            //     margin-top: ($spacer);
            // }
        }

        .nav-item {
            .nav-header {
                display: block;
                padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
                background: linear-gradient(180deg, rgba(64,61,8,1) 0%, rgba(163,158,22,1) 80%, rgba(147,142,19,1) 100%);
                color: white;
            }

            &.current {
                font-weight: bold;
            }
        }

        .mod-menu__sub {
            .nav-item {
                // border-bottom: 1px solid $gray-300;

                & + .nav-item {
                    border-top: 1px solid $gray-300;
                }
            }
        }

        .nav-link {
            &:hover,
            &:focus {
              color: #fff;
              background: $primary;
            }
          }
    }

}