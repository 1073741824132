.site-header {
  .brand {
    display: block;
    flex: 0 1 600px;
    padding: $spacer 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  .navbar-toggler {
    align-self: center;
    margin-left: auto;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.header-menu {
  @include media-breakpoint-up(lg) {
    display: none;
  }

  width: 100%;

  .navbar {
    padding: 0;
  }

  .navbar-nav {
    padding-bottom: $spacer;

    > .nav-item {
      &, & > .nav-link {
        border-bottom: 1px solid #fff;
      }

      & > .nav-link {
        margin-bottom: -1px;
      }
    }
  }

  .nav-item {
    .nav-link {
      color: white;
    }
  }

  .dropdown-toggle {
    &::after {
      position: absolute;
      display: block;
      top: calc(((($font-size-base * $line-height-base) + $spacer) / 2) - 0.2em);
      right: 1rem;
      border-top: 0.4em solid;
      border-right: 0.4em solid transparent;
      border-bottom: 0;
      border-left: 0.4em solid transparent;
    }
  }

  .dropdown-menu {
    --bs-dropdown-bg: transparent;
    --bs-dropdown-border-width: 0;
    // --bs-dropdown-padding-x: $spacer;
    // --bs-dropdown-padding-y: 0;
    --bs-nav-link-padding-x: #{$spacer};
    --bs-nav-link-padding-y: 0.5rem;

    padding: 0;

    .nav-item {
      &.active .nav-link,
      & .nav-link:hover {
        background-color: #fff;
        color: $secondary;
      }
    }
  }
}
