/* stylelint-disable no-descending-specificity */

.site-footer {
  margin-bottom: $spacer;
}

.footer-content,
.footer-copyright {
  @include make-col-ready();
  @include make-col(12);
}

.footer-content {
  padding: $spacer 0;
  background-color: #fff;
  border-top: 1px solid $gray-400;

  .nav {
    --bs-nav-link-color: $gray-600;
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    flex-direction: column;
    align-items: center;
  }

  .nav-item {
    padding-left: calc(0.5 * var(--bs-nav-link-padding-x));
    padding-right: calc(0.5 * var(--bs-nav-link-padding-x));

    &.current {
      font-weight: bold;
    }
  }

  .nav-link {
    &:hover,
    &:focus {
      color: #fff;
      background: $primary;
    }
  }

  @include media-breakpoint-up(md) {
    .nav {
      flex-direction: row;
    }

    .nav-item {
      & + .nav-item {
        border-left: 1px solid $primary;
      }
    }
  }
}

.footer-copyright {
  padding: ($spacer * 0.5) $spacer;
  border-radius: 0 0 10px 10px;
  background-color: #000;
  text-align: right;
  color: $white;
  

  a {
    color: inherit;
  }
}
